.cc_container {
  background-color: $purple !important;
  color: white !important;
  border: none !important;

  a {
    color: $navy !important;
    font-weight: bolder;
  }

  .cc_message {
    text-align: center;
  }
}
